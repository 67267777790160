import '/assets/styles/sections/home-offers.scss';
import PropTypes from 'prop-types'

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Placeholder from '/views/partials/placeholder';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

const HomeOffers = ({ data }) => {
	if(!(data && data.length === 0)) {
		const banners = data ? data.slice(0, 3) : [false, false, false];
		return (
			<section className="section home-offers">
				<div className="wrapper offers-wrap">
					<ul className="offers-list">
						{banners.map((banner, nth) => (
							<OfferBanner banner={banner} key={nth} />
						))}
					</ul>
				</div>
			</section>
		)
	}
	else {
		return false;
	}
}

HomeOffers.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([false])]),
}

const OfferBanner = ({banner}) => {
	return (
		<li className="list-offer">
			{banner ?
				<Link
					raw
					href={banner.link}
					target={banner.target}>
					<div className="offer-imagewrap">
						<Img
							cover
							className="offer-image"
							src={banner.image_url} />
					</div>
					{(banner.title || banner.sub_title) &&
						<h3 className="offer-title">
							{(banner.title && banner.sub_title) ?
								<>
									<strong>{banner.title}</strong> {banner.sub_title}
								</>
								:
								<>
									{banner.title ? banner.title : banner.sub_title}
								</>
							}
						</h3>
					}
					<Icon className="offer-cta" name="arrow-right" />
				</Link>
				:
				<>
					<Placeholder className="offer-imagewrap" />
					<Placeholder className="offer-title" />
					<Placeholder className="offer-cta" />
				</>
			}
		</li>
	)
}

OfferBanner.propTypes = {
	banner: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([false])]),
}

export default HomeOffers